import React from 'react';
import './App.css';


const MainContent = () => {
  return (
    <div className="App-content">
      <img src="logo.png" className="App-logo" alt="logo" />
      <h3>
        What if you could increase your executive function with X?
      </h3>
      <p>
        Now it's possible...
      </p>
      <button className="App-button">Sign Up</button>
    </div>
  );
}

export default MainContent;
